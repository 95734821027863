import React from 'react';
import image from "../../assets/eecd/flex_5.jpeg";

const StatsAndImage = () => {
    const stats = [
        { number: "12+", label: "Years Young" },
        { number: "24", label: "Mn. Sq. Ft. Area Developed" },
        { number: "61", label: "Mn. Sq. Ft. Area Under Development" },
        { number: "50+", label: "Awards Won" },
        { number: "7,000+", label: "Happy Families" },
    ];

    return (
        <div className="flex flex-col lg:flex-row overflow-hidden px-4 lg:px-32 py-8 lg:py-12 mb-8 lg:mb-0 h-auto lg:h-[750px]">
            {/* Stats Section */}
            <div className="lg:px-12 lg:w-2/5 flex flex-col justify-center lg:rounded-l-3xl lg:bg-marble">
                <p className="text-left text-xl lg:text-3xl font-bold text-onyx pb-4 lg:hidden">Our Achievements</p>
                {stats.map((stat, index) => (
                    <div key={index} className="py-4 text-left pl-4 lg:pl-0">
                        <p className="text-3xl lg:text-4xl font-bold text-onyx">{stat.number}</p>
                        <p className="text-base lg:text-lg text-gray-600">{stat.label}</p>
                        {index < stats.length - 1 && <hr className="my-4 border-gray-300" />}
                    </div>
                ))}
            </div>

            {/* Image Section */}
            <div className="lg:w-full flex items-center justify-center lg:justify-end">
                <img 
                    src={image}
                    alt="Happy Family" 
                    className="hidden lg:flex object-cover w-full h-full rounded-r-3xl"
                />
            </div>
        </div>
    );
};

export default StatsAndImage;
