import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const FeedbackSection = () => {
    const feedbacks = [
        {
            text: "Purchasing a home in Diamond City has been a dream come true. The entire process was seamless, and the amenities offered are top-notch. I highly recommend this township to anyone looking for luxury living.",
            name: "Mr. Arjun Mehta",
            designation: "Homeowner",
            location: "Bhubaneswar, India"
        },
        {
            text: "As a first-time homebuyer, I was anxious about the process, but the team at Eastern Estate Constructions made it incredibly easy. The construction quality is exceptional, and the community atmosphere is welcoming.",
            name: "Mrs. Priya Sharma",
            designation: "Resident",
            location: "Patna, Bihar"
        },
        {
            text: "The attention to detail and the commitment to quality at Eastern Estate Constructions is unparalleled. The township is well-planned, with all the amenities one could ask for. I'm proud to be a part of this community.",
            name: "Mr. Rohit Verma",
            designation: "Investor",
            location: "Ranchi, Jharkhand"
        },
        {
            text: "Living in the Diamond City township has been an amazing experience. The location is perfect, and the facilities provided make everyday living convenient and luxurious.",
            name: "Mrs. Sunita Joshi",
            designation: "Resident",
            location: "Cuttack, Odisha"
        },
        {
            text: "Eastern Estate Constructions has delivered on every promise. From timely possession to exceptional after-sales service, they have exceeded my expectations. This is the best investment I've made.",
            name: "Mr. Vikram Singh",
            designation: "Property Owner",
            location: "Bhubaneswar, India"
        }
    ];
    

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,                // Transition speed
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,             
        autoplaySpeed: 2000,        // Time between slides
        pauseOnHover: true,         // Pauses autoplay on hover
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="py-12 lg:px-12 h-[450px] lg:h-[400px] ">
            <h2 className="text-xl lg:text-2xl font-bold mb-8 text-center">Our happy clients say about us</h2>
            <Slider {...settings}>
                {feedbacks.map((feedback, index) => (
                    <div key={index} className="py-4">
                        <div className="p-6 mr-16 bg-marble rounded-lg shadow-md transform transition-transform duration-300 hover:scale-105 h-64 sm:h-48 flex flex-col justify-between">
                            <div className="overflow-y-auto max-h-32 mb-4"> {/* Limit height and hide overflow */}
                                <p className="text-onyx overflow-hidden text-ellipsis">{feedback.text}</p>
                            </div>
                            <div>
                                <p className="font-semibold">{feedback.name}</p>
                                <p className="text-gray-600">{feedback.designation}, {feedback.location}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default FeedbackSection;
