import React from 'react';
import { FaAward, FaCogs, FaBuilding, FaClipboardCheck } from 'react-icons/fa'; // Using appropriate icons

const WhyChooseUs = () => {
    const options = [
        {
            icon: <FaAward className="text-champagne text-3xl" />,
            title: "ISO 9001:2015",
            description: "EECD Pvt. Ltd. is an ISO 9001:2015 certified construction company in India, executing major Civil Works including construction of Buildings, Interiors, and Environmental Projects."
        },
        {
            icon: <FaCogs className="text-champagne text-3xl" />,
            title: "10+ YEARS OF EXPERIENCE",
            description: "EECD is managed by Highly Qualified & Experienced Professionals with a strong background in construction."
        },
        {
            icon: <FaBuilding className="text-champagne text-3xl" />,
            title: "BEST DEVELOPERS",
            description: "EECD has a wide range of experience in developing townships, customized builder flats, apartments, and individual houses."
        },
        {
            icon: <FaClipboardCheck className="text-champagne text-3xl" />,
            title: "QUALITY PROJECTS",
            description: "EECD has the most appropriate working team & resources to build major projects, delivering quality construction projects over the past decades."
        }
    ];

    return (
        <div className="bg-marble pt-8 mt-12 text-onyx">
            <h2 className="text-xl font-bold mb-8 text-left mx-4 sm:mx-8 lg:mx-36">Why Choose Us?</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 pb-16 justify-items-center px-8 lg:px-28">
                {options.map((option, index) => (
                    <div key={index} className="flex flex-col items-start max-w-xs text-left">
                        {option.icon}
                        <h3 className="text-xl font-semibold mt-4">{option.title}</h3>
                        <p className="mt-2 text-gray-600">{option.description}</p>
                    </div>
                ))}
            </div>

            <section className="bg-onyx grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-8 py-10 sm:px-16 text-center">
                <div>
                    <h3 className="text-xl lg:text-4xl font-bold text-champagne">20+</h3>
                    <p className="text-[10px] lg:text-sm text-gray-600">Years of Experience</p>
                </div>
                <div>
                    <h3 className="text-xl lg:text-4xl font-bold text-champagne">50+</h3>
                    <p className="text-[10px] lg:text-sm text-gray-600">Awards Won</p>
                </div>
                <div>
                    <h3 className="text-xl lg:text-4xl font-bold text-champagne">10 Lakhs+</h3>
                    <p className="text-[10px] lg:text-sm text-gray-600">Sq. ft. Area Developed</p>
                </div>
                <div>
                    <h3 className="text-xl lg:text-4xl font-bold text-champagne">5000+</h3>
                    <p className="text-[10px] lg:text-sm text-gray-600">Happy Families</p>
                </div>
            </section>
        </div>
    );
};

export default WhyChooseUs;
