import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import {Route, Routes, useLocation} from 'react-router-dom';
import { analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';

const EMI_Calculator = () => {
    const [loanAmount, setLoanAmount] = useState(8000000);
    const [interestRate, setInterestRate] = useState(6.75);
    const [loanTenure, setLoanTenure] = useState(20);

    // EMI Calculation Logic
    const calculateEMI = () => {
        logEvent(analytics, "EMI used", {
            page_path: [loanAmount, interestRate, loanTenure],
        });

        const monthlyRate = interestRate / 12 / 100;
        const numberOfMonths = loanTenure * 12;
        const EMI = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfMonths)) / (Math.pow(1 + monthlyRate, numberOfMonths) - 1);
        return Math.round(EMI);
    };

    const calculateTotalPayment = () => {
        return calculateEMI() * loanTenure * 12;
    };

    const calculateTotalInterest = () => {
        return calculateTotalPayment() - loanAmount;
    };

    const data = [
        { name: 'Interest', value: calculateTotalInterest(), color: "#D2B68A" },
        { name: 'Principal', value: loanAmount, color: "#222D52" },
    ];

    return (
        <div className="flex flex-col lg:flex-row my-8 mb-16 lg:mx-48 shadow-lg rounded-xl bg-pearl">
            {/* Left Section: Sliders */}
            <div className="flex flex-col w-full lg:w-1/2 p-4 py-6">
                <h2 className="text-2xl font-bold text-onyx">EMI Calculator</h2>

                {/* Loan Amount Slider */}
                <div className="mt-4">
                    <label className="block text-gray-700 font-semibold">Loan Amount</label>
                    <Slider
                        value={loanAmount}
                        onChange={(e, val) => setLoanAmount(val)}
                        min={100000}
                        max={80000000}
                        step={100000}
                        valueLabelDisplay="auto"
                        aria-labelledby="loan-amount-slider"
                    />
                    <div className="flex justify-between text-sm text-gray-500">
                        <span>₹1 Lac</span>
                        <span>₹8 Cr</span>
                    </div>
                </div>

                {/* Interest Rate Slider */}
                <div className="mt-4">
                    <label className="block text-gray-700 font-semibold">Interest Rate (% P. A.)</label>
                    <Slider
                        value={interestRate}
                        onChange={(e, val) => setInterestRate(val)}
                        min={1}
                        max={30}
                        step={0.1}
                        valueLabelDisplay="auto"
                        aria-labelledby="interest-rate-slider"
                    />
                    <div className="flex justify-between text-sm text-gray-500">
                        <span>1%</span>
                        <span>30%</span>
                    </div>
                </div>

                {/* Loan Tenure Slider */}
                <div className="mt-4">
                    <label className="block text-gray-700 font-semibold">Loan Tenure</label>
                    <Slider
                        value={loanTenure}
                        onChange={(e, val) => setLoanTenure(val)}
                        min={1}
                        max={30}
                        step={1}
                        valueLabelDisplay="auto"
                        aria-labelledby="loan-tenure-slider"
                    />
                    <div className="flex justify-between text-sm text-gray-500">
                        <span>1 Year</span>
                        <span>30 Years</span>
                    </div>
                </div>
            </div>

            {/* Right Section: Pie Chart and EMI Breakdown */}
            <div className="flex flex-col w-full lg:w-1/2 p-4 py-6 justify-center items-center">
                <ResponsiveContainer width="100%" height={280}>
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"
                            innerRadius={90}
                            outerRadius={130}
                            fill="#8884d8"
                            paddingAngle={5}
                            startAngle={180}
                            endAngle={0}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                
                {/* EMI Breakdown */}
                <div className="text-center mt-2">
                    <h3 className="text-xl font-bold text-onyx">Your Monthly Home EMI</h3>
                    <p className="text-3xl font-semibold text-gray-700">₹{calculateEMI()}</p>
                </div>

                {/* Total Breakdown */}
                <div className="flex justify-between w-full mt-4">
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Interest Amount</p>
                        <p className="text-lg text-red-500">₹{calculateTotalInterest()}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Principal Amount</p>
                        <p className="text-lg text-blue-500">₹{loanAmount}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Total Payable Amount</p>
                        <p className="text-lg text-onyx">₹{calculateTotalPayment()}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EMI_Calculator;
