import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import eecdPartLogo from "../assets/navbarIcons/eecdPartLogo.png";
import ContactButton from "./ContactButton";
import FloatingContactButton from "./FloatingContactButton";
import aboutus_logo from '../assets/navbarIcons/aboutus_logo_2.png';
import projects_logo from '../assets/navbarIcons/real-estate.png';

function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [showProjectsSubmenu, setShowProjectsSubmenu] = useState(false); 
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleProjectsSubmenu = () => {
        setShowProjectsSubmenu(!showProjectsSubmenu);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const menuItems = ['About Us', 'Projects', 'Contact Us'];

    const projectsSubmenuItems = [
        { name: "Techno City, Patna", path: "/technocitypatna" },
        { name: "Satellite City, Muzaffarpur", path: "/satellitecitymuzaffarpur" },
        { name: "Diamond City, Ranchi", path: "/diamondcityranchi" },
        { name: "Diamond City, Bhubaneswar", path: "/diamondcitybhubaneswar" },
    ];

    return (
        <header
            className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 bg-pearl ${
                scrolled ? 'shadow-lg' : ''
            } ${menuOpen ? 'bottom-0' : 'lg:bottom-auto'}`}
        >
            {/* Top bar containing logo and menu items */}
            <div className="hidden lg:flex flex-row container mx-auto justify-between items-center lg:justify-between">
                {/* Logo Section */}
                <a href="/" className="flex items-center">
                    <img src={eecdPartLogo} alt="Logo" className="h-24"/>
                </a>

                {/* Menu Items */}
                <nav className="hidden lg:flex">
                    <ul className="flex space-x-4 mt-3">
                        {menuItems.map((item, index) => {
                            const path = `/${item.toLowerCase().replace(/\s+/g, '')}`;
                            const isActive = location.pathname === path;

                            if (item === "Projects") {
                                return (
                                    <li key={index} className="relative">
                                        <button
                                            onClick={toggleProjectsSubmenu}
                                            className={`block mt-2 py-2 px-4 rounded-md font-semibold transition hover:bg-onyx hover:text-marble ${
                                                isActive ? 'bg-onyx text-marble' : 'text-onyx'
                                            }`}
                                        >
                                            {item}
                                        </button>
                                        {/* Submenu for Projects */}
                                        {showProjectsSubmenu && (
                                            <ul className="absolute left-0 mt-2 w-72 bg-white shadow-lg rounded-xl">
                                                {projectsSubmenuItems.map((submenuItem, subIndex) => (
                                                    <li key={subIndex}>
                                                        <a href={submenuItem.path} className="block px-4 py-2 text-left  border-b-2 border-champagne rounded-xl hover:bg-onyx hover:text-marble">
                                                            {submenuItem.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                );
                            }

                            return (
                                <li key={index}>
                                    <a href={path}
                                        className={`block mt-2 py-2 px-4 rounded-md font-semibold transition hover:bg-onyx hover:text-marble ${
                                            isActive ? 'bg-onyx text-marble' : 'text-onyx'
                                        }`}>
                                        {item}
                                    </a>
                                </li>
                            );
                        })}
                        <ContactButton/>
                    </ul>
                </nav>

                {/* Hamburger Icon for mobile screens */}
                <div className="lg:hidden flex flex-row">
                    <ContactButton/>
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg className="h-6 w-6" viewBox="0 0 24 24">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                            />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Bottom Menu for mobile screens */}
            <nav
                className={`lg:hidden fixed container mx-auto bottom-0 right-0 z-50 bg-gray-200 flex flex-col items-center justify-center w-16 rounded-l-3xl bg-marble`}>
                {/* Logo */}
                <a href="/" className="mb-4">
                    <img src={eecdPartLogo} alt="Logo" className="h-14 w-14" />
                </a>

                {/* About Us */}
                <a href="/aboutus" className="mb-4">
                    <img src={aboutus_logo} alt="About Us" className="h-9 w-9" />
                </a>

                {/* Projects */}
                <div className="relative mb-4">
                    <button className="block py-2 px-4" onClick={toggleProjectsSubmenu}>
                    <img src={projects_logo} alt="Projects" className="h-10 w-10" />
                    </button>
                    {showProjectsSubmenu && (
                    <ul className="absolute -top-12 right-16 w-48 bg-white shadow-lg rounded-xl">
                        {projectsSubmenuItems.map((submenuItem, subIndex) => (
                        <li key={subIndex}>
                            <a href={submenuItem.path} className="block px-4 py-2 rounded-xl hover:bg-gray-200 text-left border-b-2">
                                {submenuItem.name}
                            </a>
                        </li>
                        ))}
                    </ul>
                    )}
                </div>

                {/* Contact Button */}
                <FloatingContactButton isPartOfNavbar={true}/>
                <ContactButton className="flex" />
            </nav>
        </header>
    );
}

export default NavBar;
