// servicePageData.js
import diamondcitybhubaneswar from './DiamondCityBhubaneswar/diamondcitybhubaneswar'
import satellitecitymuzaffarpur from './SatteliteCity/satellitecitymuzaffarpur'
import technocitypatna from './TechnoCity/technocitypatna'
import diamondcityranchi from './DiamondCityRanchi/diamondcityranchi'

export const projectPageData = {
  "diamondcitybhubaneswar": diamondcitybhubaneswar,
  "diamondcityranchi": diamondcityranchi,
  "satellitecitymuzaffarpur": satellitecitymuzaffarpur,
  "technocitypatna": technocitypatna,
};