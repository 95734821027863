import bg from './pictures/s_city.jpg';
import { faShieldAlt, faCamera, faWifi, faTree, faBuilding, faGamepad, faSwimmer, faDumbbell, faPrayingHands, faUtensils, faStore, faBank, faCubes, faWater } from '@fortawesome/free-solid-svg-icons';
import simplexEco from './floorplans/simplex_eco.jpg';
import simplexGold from './floorplans/simplex_gold.jpg';
import duplex from './floorplans/duplex.jpg';

// Import all images from the 'clients' folder
const imagesList = require.context('./pictures', false, /\.(png|jpe?g|svg)$/);
const images = imagesList.keys().map((image) => imagesList(image));

const satellitecitymuzaffarpur = {
    heading: "Satellite City, Muzaffarpur",
    RERA: "BRERAP00080-1/19/R-150/2018", // From the image for Satellite City
    bg: bg,
    images: images,
    location: "Muzaffarpur, Bihar",
    type: "Township",
    status: "Under Construction",
    amenities: [
      { title: 'Club House', icon: faBuilding },
      { title: 'Garden', icon: faTree },
      { title: 'Temple', icon: faPrayingHands },
      { title: 'Swimming Pool', icon: faSwimmer },
      { title: 'Kids Play Zone', icon: faGamepad },
      { title: 'Yoga / Meditation Center', icon: faPrayingHands },
    ],
    configuration: "1, 2 & 3 BHK Villas",
    possession: "-",
    unitSize: "Ranging from 455 sq.ft. to 1536 sq.ft.",
    bhkDetails: [
      {
        bhk: "1 BHK (SIMPLEX ECONOMY)",
        size: "455 sq.ft",
        price: "₹ 25 Lakhs* Onwards",
        image: [simplexEco]
      },
      {
        bhk: "2 BHK (SIMPLEX GOLD)",
        size: "720 sq.ft",
        price: "₹ 35 Lakhs* Onwards",
        image: [simplexGold]
      },
      {
        bhk: "3 BHK (DUPLEX GOLD)",
        size: "1536 sq.ft",
        price: "₹ 55 Lakhs* Onwards",
        image: [duplex]
      }
    ],
    highlight: {
      heading: "Satellite City, Muzaffarpur- Best the city has to offer",
      content: ""
    },
    locationSection: {
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3585.046367033606!2d85.32005957637303!3d26.03203887718304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed140eaaaaaaab%3A0x6cac2dc9ca684360!2sSatellite%20City%2C%20Eastern%20Estate%20Construction%20%26%20Developers%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1725696505439!5m2!1sen!2sin" ,
      reasons: [
          [
              { text: "Satellite City, Muzaffarpur is located near several ", isBold: false },
              { text: "schools & colleges", isBold: true },
              { text: " such as ", isBold: false },
              { text: "Delhi Public School", isBold: true },
              { text: " (3 km), ", isBold: false },
              { text: "Birla Open Mind School", isBold: true },
              { text: " (0.7 km), ", isBold: false },
              { text: "Pantocreater School", isBold: true },
              { text: " (1.5 km), ", isBold: false },
              { text: "Govt. B.Ed College", isBold: true },
              { text: " (2 km), ", isBold: false },
              { text: "Mount Littra School", isBold: true },
              { text: " (1 km), and upcoming ", isBold: false },
              { text: "D.A.V Public School", isBold: true },
              { text: " (2 km) and ", isBold: false },
              { text: "St. Xavier School", isBold: true },
              { text: " (2.5 km).", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "corporates", isBold: true },
              { text: " include ", isBold: false },
              { text: "Pusa Agriculture Krishi Vigyan Kendra", isBold: true },
              { text: " (1 km), ", isBold: false },
              { text: "Warehouse Amul", isBold: true },
              { text: " (2 km), ", isBold: false },
              { text: "Warehouse Patanjali", isBold: true },
              { text: " (2 km), and ", isBold: false },
              { text: "Honda Motors Sales & Service Center", isBold: true },
              { text: " (2.5 km).", isBold: false }
          ],
          [
              { text: "Railway stations", isBold: true },
              { text: " nearby include ", isBold: false },
              { text: "Turki Railway Station", isBold: true },
              { text: " (5 km), ", isBold: false },
              { text: "Kurhani Railway Station", isBold: true },
              { text: " (7 km), and ", isBold: false },
              { text: "Muzaffarpur Junction", isBold: true },
              { text: " (15 km).", isBold: false }
          ],
          [
              { text: "Hospitals", isBold: true },
              { text: " near the area include ", isBold: false },
              { text: "RDJM College & Hospital", isBold: true },
              { text: " (0.7 km), ", isBold: false },
              { text: "Tirhut Central Hospital", isBold: true },
              { text: " (4 km), and ", isBold: false },
              { text: "Govt. Hospital Kurhani", isBold: true },
              { text: " (15 km).", isBold: false }
          ],
          [
              { text: "The ", isBold: false },
              { text: "Muzaffarpur-Hajipur NH", isBold: true },
              { text: " is just ", isBold: false },
              { text: "1.5 km", isBold: true },
              { text: " away, ensuring excellent connectivity.", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "temples", isBold: true },
              { text: " include ", isBold: false },
              { text: "Naulakha Mandir", isBold: true },
              { text: " (3 km), ", isBold: false },
              { text: "Baglamukhi Mandir", isBold: true },
              { text: " (14 km), and ", isBold: false },
              { text: "Baba Garibnath Dham", isBold: true },
              { text: " (15 km).", isBold: false }
          ],
          [
              { text: "The proposed ", isBold: false },
              { text: "Patahi Airport", isBold: true },
              { text: " (8 km) is under development, while ", isBold: false },
              { text: "JPN Airport, Patna", isBold: true },
              { text: " (55 km) is the nearest operational airport.", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "hotels", isBold: true },
              { text: " include ", isBold: false },
              { text: "Highway Dhaba", isBold: true },
              { text: " (2 km), ", isBold: false },
              { text: "Atithi Hotel", isBold: true },
              { text: " (10 km), and ", isBold: false },
              { text: "Rup Basant Hotel", isBold: true },
              { text: " (10 km).", isBold: false }
          ],
          [
              { text: "For shopping, you can visit ", isBold: false },
              { text: "Race Bazar", isBold: true },
              { text: " (1.6 km) for retail and groceries.", isBold: false }
          ],
          [
              { text: "The closest ", isBold: false },
              { text: "stadiums", isBold: true },
              { text: " are ", isBold: false },
              { text: "Kampanibag Stadium", isBold: true },
              { text: " (16 km) and ", isBold: false },
              { text: "Sikandarpur Stadium", isBold: true },
              { text: " (17 km).", isBold: false }
          ],
          [
              { text: "Police stations", isBold: true },
              { text: " nearby include ", isBold: false },
              { text: "Turki Thana", isBold: true },
              { text: " (2 km) and ", isBold: false },
              { text: "Nagar Thana", isBold: true },
              { text: " (15 km).", isBold: false }
          ],
          [
              { text: "Main Chowks", isBold: true },
              { text: " near the area include ", isBold: false },
              { text: "Chhajan Gonu Chowk", isBold: true },
              { text: " (300 meters), ", isBold: false },
              { text: "Turki Chowk", isBold: true },
              { text: " (1.5 km), and ", isBold: false },
              { text: "Sakri Chowk", isBold: true },
              { text: " (3 km).", isBold: false }
          ]
      ]
  }
};

export default satellitecitymuzaffarpur;
