import { EmailJSResponseStatus } from 'emailjs-com';
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const SiteVisitForm = ({ heading }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        consent: true,
        loan: true
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Send email using EmailJS
        emailjs.send(
            'service_r1gz4bl',   // Replace with your EmailJS service ID
            'template_mldvs8q',   // Replace with your EmailJS template ID
            formData,
            'R_5m_rCvYgVXMFU5S'        // Replace with your EmailJS user ID
        )
        .then((response) => {
            setIsSubmitting(false);
            setIsSuccess(true);
            setFormData({ name: '', email: '', phone: '', message: '' });
            alert("We have heard your query, you will be contacted very soon.")
        })
        .catch((error) => {
            console.log('FAILED...', error);
            setIsSubmitting(false);
        });
    };

    return (
        <div className="max-w-full sm:max-w-lg lg:max-w-md mx-auto p-4 sm:p-6 lg:p-8 bg-gray-100 rounded-lg shadow-lg">
            <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 sm:mb-6 text-onyx">{heading}</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3 sm:mb-4">
                    <input
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 sm:p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-600"
                    />
                </div>
                <div className="mb-3 sm:mb-4">
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 sm:p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-600"
                    />
                </div>
                <div className="mb-3 sm:mb-4">
                    <div className="flex">
                        <select className="w-20 py-2 sm:py-3 border border-gray-300 rounded-l focus:outline-none focus:ring-2 focus:ring-gray-600">
                            <option value="+91">🇮🇳+91</option>
                            {/* Add other country codes as needed */}
                        </select>
                        <input
                            type="text"
                            name="phone"
                            placeholder="Enter Number"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full p-2 sm:p-3 border border-gray-300 rounded-r focus:outline-none focus:ring-2 focus:ring-gray-600"
                        />
                    </div>
                </div>
                <div className="mb-3 sm:mb-4">
                    <textarea
                        name="message"
                        placeholder="Enter Message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-2 sm:p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-gray-600"
                        rows="4"
                    ></textarea>
                </div>
                <div className="mb-3 sm:mb-4 flex items-start">
                    <input
                        type="checkbox"
                        name="consent"
                        checked={formData.consent}
                        onChange={handleChange}
                        className="mr-2 mt-1 focus:ring-2 focus:ring-gray-600"
                    />
                    <p className="text-gray-600 text-sm sm:text-base text-left">
                        I authorize company representatives to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DNC/NDNC.
                    </p>
                </div>
                <div className="mb-3 sm:mb-4 flex items-start">
                    <input
                        type="checkbox"
                        name="loan"
                        checked={formData.loan}
                        onChange={handleChange}
                        className="mr-2 mt-1 focus:ring-2 focus:ring-gray-600"
                    />
                    <p className="text-gray-600 text-sm sm:text-base text-left">
                        I am interested in home loans.
                    </p>
                </div>
                <div>
                    <button
                        type="submit"
                        className="w-full bg-onyx text-white p-2 sm:p-3 rounded-lg font-semibold hover:bg-gray-800 transition duration-300"
                    >
                        Submit Now
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SiteVisitForm;
