import React, { useState } from 'react';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import { useEffect } from 'react';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

// Set the app element for accessibility
Modal.setAppElement('#root');

const ContactUsModal = ({ isOpen, closeModal }) => {

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Know More', {
            page_path: 'From where',
        });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Send email using EmailJS
        emailjs.send(
            'service_r1gz4bl',   // Replace with your EmailJS service ID
            'template_mldvs8q',   // Replace with your EmailJS template ID
            formData,
            'R_5m_rCvYgVXMFU5S'        // Replace with your EmailJS user ID
        )
        .then((response) => {
            setIsSubmitting(false);
            setIsSuccess(true);
            setFormData({ name: '', email: '', phone: '', message: '' });
            closeModal()
            alert("We hava heard your query, you will be contacted very soon.")
        })
        .catch((error) => {
            console.log('FAILED...', error);
            setIsSubmitting(false);
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="Contact Us"
            className="fixed inset-0 flex items-center justify-center p-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="bg-pearl rounded-lg shadow-xl w-full max-w-lg p-8 relative">
                <button
                    onClick={closeModal}
                    className="absolute top-4 right-4 text-champagne text-2xl font-bold hover:text-onyx transition duration-300"
                >
                    &times;
                </button>
                <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-onyx">Contact Us to Know More</h2>
                
                {/* Contact Form */}
                {isSuccess && <p className="text-green-600 mb-4">Your message has been sent successfully!</p>}
                
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="name" className="block text-lg font-semibold text-onyx mb-1">Your Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-champagne"
                            placeholder="Enter your name"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-lg font-semibold text-onyx mb-1">Your Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-champagne"
                            placeholder="Enter your email"
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-lg font-semibold text-onyx mb-1">Your Phone</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-champagne"
                            placeholder="Enter your phone number"
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-lg font-semibold text-onyx mb-1">Your Message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                            rows="4"
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-champagne"
                            placeholder="Enter your message"
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-champagne text-white py-2 px-6 rounded-lg hover:bg-onyx hover:text-champagne transition duration-300"
                    >
                        {isSubmitting ? 'Sending...' : 'Submit'}
                    </button>
                </form>
            </div>
        </Modal>
    );
};

export default ContactUsModal;
