import React, { useState } from 'react';
import KnowMore from '../../../components/KnowMore';

const PriceListSection = React.forwardRef(({ priceList }, ref) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    const openContactModal = () => {
        setIsContactModalOpen(true);
    };

    const closeContactModal = () => {
        setIsContactModalOpen(false);
    };

    return (
        <section id="pricelist" ref={ref} className="bg-pearl text-left py-8 lg:pb-12">
            <h3 className="text-2xl lg:text-4xl font-bold mb-8 text-onyx">Unlock the Door to Affordable Luxury</h3>
            
            {/* Grid Layout for Price List */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {priceList.map((item, index) => (
                    <div key={index} className="bg-marble p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                        <h4 className="text-lg font-bold text-onyx py-2">{item.bhk} Flats</h4>
                        <div className="h-1 w-full bg-champagne mb-2"></div>
                        {/* <p className="text-2xl font-semibold text-onyx my-2">{item.price}</p> */}
                        <p className="text-gray-700 mb-4">Size: {item.size}</p>
                        <button onClick={openContactModal} className="bg-champagne text-onyx py-2 px-4 rounded-lg shadow hover:bg-yellow-600 transition duration-300">
                            Ask for Price 
                            {/* &rarr; */}
                        </button>
                    </div>
                ))}
            </div>

            {/* Modal for Contact */}
            <KnowMore isOpen={isContactModalOpen} closeModal={closeContactModal} />
        </section>
    );
});

export default PriceListSection;
