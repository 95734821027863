import React, { useState } from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import KnowMore from '../../../components/KnowMore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Set the app element for accessibility
Modal.setAppElement('#root');

const FloorPlanSection = React.forwardRef(({ floorPlans }, ref) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false); // Add state for Contact Us modal
    const [selectedPlan, setSelectedPlan] = useState(null); // State for selected plan

    const openContactModal = (plan) => {
        setSelectedPlan(plan);
        setIsContactModalOpen(true);
    };

    const closeContactModal = () => {
        setIsContactModalOpen(false);
    };

    const carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        arrows: false,
        centerMode: false, 
    };

    return (
        <section id="floorplan" ref={ref} className="py-12 bg-pearl text-left">
            <h2 className="text-2xl lg:text-4xl font-bold mb-8 text-onyx">Floor Plans</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {floorPlans.map((plan, index) => (
                    <div key={index} className="bg-marble p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                        <h4 className="text-lg lg:text-xl font-semibold mb-4 text-champagne">{plan.bhk}</h4>
                        <p className="text-gray-700 mb-4">RERA Carpet Area: {plan.size}</p>
                        <img
                            src={plan.image[0]}
                            alt={plan.type}
                            className="w-full h-48 mb-4 rounded-lg object-cover filter blur-sm"
                        />
                        <button
                            className="bg-transparent border-2 border-champagne text-champagne py-2 px-4 rounded-lg hover:bg-champagne hover:text-white transition duration-300"
                            onClick={() => openContactModal(plan)}
                        >
                            View Specifications
                        </button>
                    </div>
                ))}
            </div>

            {/* Modal for Floor Plan Specifications */}
            <Modal
                isOpen={isContactModalOpen}
                onRequestClose={closeContactModal}
                contentLabel="Floor Plan Specifications"
                className="fixed inset-0 flex items-center justify-center p-4"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            >
                {selectedPlan && (
                    <div className="bg-pearl rounded-lg shadow-xl w-full max-w-3xl p-8 relative">
                        <button
                            onClick={closeContactModal}
                            className="absolute top-4 right-4 text-champagne text-2xl font-bold hover:text-onyx transition duration-300"
                        >
                            &times;
                        </button>
                        <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-onyx">{selectedPlan.bhk}</h2>
                        <p className="text-lg lg:text-xl text-gray-700 mb-8">{selectedPlan.size}</p>

                        {selectedPlan.image.length > 1 ? (
                            <Slider {...carouselSettings}>
                                {selectedPlan.image.map((img, index) => (
                                    <div key={index}>
                                        <img
                                            src={img}
                                            alt={`${selectedPlan.type} - ${index + 1}`}
                                            className="w-full h-[500px] object-fit rounded-lg pr-16"
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <img
                                src={selectedPlan.image[0]}
                                alt={selectedPlan.type}
                                className="w-full h-96 rounded-lg mb-6"
                            />
                        )}

                        <button
                            onClick={closeContactModal}
                            className="bg-champagne text-white py-2 px-6 rounded-lg hover:bg-onyx hover:text-champagne transition duration-300"
                        >
                            Close
                        </button>
                    </div>
                )}
            </Modal>
        </section>
    );
});

export default FloorPlanSection;
