import React from 'react';

const HighlightSection = React.forwardRef(({ title, content }, ref) => {
    return (
        <section id="highlight" ref={ref} className="py-8 lg:py-12 bg-pearl text-onyx">
            <h2 className="text-2xl lg:text-4xl font-bold mb-6">{title}</h2>
            <p className="text-lg lg:text-xl text-gray-700 mb-6">{content}</p>
            {/* <button className="bg-champagne text-onyx font-semibold px-6 py-2 rounded-lg hover:bg-yellow-400 transition-colors">
                Read more
            </button> */}
        </section>
    );
});

export default HighlightSection;
