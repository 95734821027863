const CarouselButtons = ({goToPreviousSlide, goToNextSlide}) => {
    return (
        <div className="mt-4 sm:mt-0 hidden lg:flex gap-x-2">
            <button onClick={goToPreviousSlide} className="text-white text-xl bg-gray-800 hover:bg-gray-600 px-4 py-2 rounded-full shadow-md transition-all duration-300 ease-in-out">
                &lt;
            </button>
            <button onClick={goToNextSlide} className="text-white text-xl bg-gray-800 hover:bg-gray-600 px-4 py-2 rounded-full shadow-md transition-all duration-300 ease-in-out">
                &gt;
            </button>
        </div>
    );
}

export default CarouselButtons;