import React from 'react';
import { LiaPhoneVolumeSolid } from "react-icons/lia";

const ContactButton = ({ className }) => {
    return (
        <div className={`${className} flex justify-center items-center mb-4`}>
            <div className="rounded-full p-1">
                <a href="tel:+919939366036" className="bg-onyx text-white flex items-center justify-center px-4 py-2 rounded-full shadow-lg">
                    <LiaPhoneVolumeSolid className="text-yellow-300 animate-pulse my-1 text-2xl" />
                    <span className="hidden lg:flex font-semibold text-lg pl-2">+91-9939366036</span>
                </a>
            </div>
        </div>
    );
};

export default ContactButton;
