import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import HomePage from './Screens/HomePage';
import NavBar from "./components/NavBar";
import BottomBar from "./components/BottomBar";
import FloatingContactButton from "./components/FloatingContactButton";
import PropertyPage from './Screens/PropertyPage';
import AboutUs from './Screens/AboutUs';
import ContactUs from './Screens/ContactUs';
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

function App() {
    const location = useLocation();

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'entry', {
            page_path: location.pathname,
        });
    }, [location]);

    const isHomePage = location.pathname === '/';

    return (

        <div className="App">
            <NavBar textColor={isHomePage ? 'text-white' : 'text-white'}
                    bordercColor=
                        {isHomePage ? 'border-white' : 'border-white'}

                    bgColor={isHomePage ? 'bg-white' : 'bg-white'
            } />
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/aboutus" element={<AboutUs/>}/>
                <Route path="/:projectId" element={<PropertyPage/>}/>
                <Route path="/contactus" element={<ContactUs/>}/>
            </Routes>
            <BottomBar/>
        </div>

    );
}

export default App;