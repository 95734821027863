import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AmenitiesSection = React.forwardRef((props, ref) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <section id="amenities" ref={ref} className="text-onyx bg-pearl py-8 lg:py-12 h-52 lg:h-full">
            <h3 className="text-2xl lg:text-4xl font-bold mb-8 text-left">Diamond City - Amenities</h3>

            {/* Carousel for small screens */}
            <div className="block lg:hidden">
                <Slider {...settings}>
                    {props.amenities.map((amenity, index) => (
                        <div key={index} className="flex flex-row items-center gap-4 mb-4">
                            <div className="w-12 h-12 rounded-full bg-white text-champagne flex items-center justify-center">
                                <FontAwesomeIcon icon={amenity.icon} className="text-2xl" />
                            </div>
                            <h3 className="text-lg font-semibold">{amenity.title}</h3>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Grid layout for larger screens */}
            <div className="hidden lg:grid grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-6">
                {props.amenities.map((amenity, index) => (
                    <div key={index} className="flex items-center mb-4">
                        <div className="w-16 h-16 rounded-full bg-white text-champagne flex items-center justify-center">
                            <FontAwesomeIcon icon={amenity.icon} className="text-2xl" />
                        </div>
                        <h3 className="text-lg font-semibold ml-4">{amenity.title}</h3>
                    </div>
                ))}
            </div>
        </section>
    );
});

export default AmenitiesSection;
