import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { projectPageData } from '../../assets/projectPageData'; // Importing project data
import { FaTools, FaRegClock, FaThumbsUp, FaHeart } from 'react-icons/fa'; // Importing icons

const featuresData = [
  {
    id: 1,
    title: 'Professional Builder',
    description: 'We specialize in building expansive townships, establishing ourselves as premier professional builders.',
    icon: <FaTools className="text-4xl text-champagne mb-4" />,
  },
  {
    id: 2,
    title: 'We Deliver Quality',
    description: 'Combining quality with quantity is our forte. We focus primarily on delivering exceptional quality.',
    icon: <FaThumbsUp className="text-4xl text-champagne mb-4" />,
  },
  {
    id: 3,
    title: 'Always On Time',
    description: 'Timely possession is our commitment because we understand the true value of time.',
    icon: <FaRegClock className="text-4xl text-champagne mb-4" />,
  },
  {
    id: 4,
    title: 'We Are Passionate',
    description: 'Work is our worship and passion. Our enthusiastic team is driven by a love for what they do.',
    icon: <FaHeart className="text-4xl text-champagne mb-4" />,
  },
];

const projectDataArray = Object.values(projectPageData); // Convert project data to array

const FeaturesSection = () => {
  return (
    <section className="lg:flex hidden relative mx-4 lg:mx-[120px] bg-pearl shadow-xl rounded-2xl py-4 -top-24">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {featuresData.map((feature, index) => (
          <div
            key={feature.id}
            className={`flex flex-col items-start text-left p-6 transition-shadow duration-300 hover:shadow-lg hover:rounded-3xl ${index !== featuresData.length - 1 ? 'lg:border-r-2 border-marble' : ''}`}
          >
            {feature.icon}
            <h3 className="text-xl font-bold text-onyx">{feature.title}</h3>
            <p className="text-gray-600">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

const DiamondCitySection = () => {

  return (
    <div className="relative flex flex-col items-center lg:pt-24">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay
        interval={3000}
        showIndicators={false}
      >
        {projectDataArray.slice(0, 2).map((project, index) => (
          <div key={index} className="relative max-w-screen lg:h-full flex flex-col items-start justify-center">
            <div className="relative w-full lg:h-full">
              <img
                src={project.bg} // Assuming project has images
                alt={project.heading}
                className="item-start w-full h-[500px] lg:h-full object-cover opacity-80"
              />
              <div className="absolute inset-0 items-end justify-start lg:p-8 flex lg:mx-24 lg:my-24">
                <div className="relative z-30 p-4 lg:p-8 rounded-t-xl lg:rounded-2xl w-full lg:max-w-[700px] text-left bg-silk bg-opacity-70 text-onyx shadow-2xl">
                  <h1 className="text-xl lg:text-4xl font-bold lg:mb-4">{project.heading}</h1>
                  <ul className="list-none text-xl font-semibold">
                    <li className="py-1 text-sm lg:text-lg">RERA: {project.RERA}</li>
                    <li className="py-1 text-sm lg:text-lg">Location: {project.location}</li>
                    <li className="py-1 text-sm lg:text-lg">Type: {project.type}</li>
                    <li className="py-1 text-sm lg:text-lg">Status: {project.status}</li>
                  </ul>
                  <div className="lg:py-6">
                  <a href={`/${project.heading.toLowerCase().replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, '')}`} className="px-2 lg:px-6 py-1 lg:py-3 bg-onyx text-silk border-2 border-onyx font-semibold rounded-xl hover:bg-silk hover:text-onyx transition-colors">
                    Explore More
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <FeaturesSection />
    </div>
  );
};

export default DiamondCitySection;
