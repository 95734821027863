import React, { useState, useEffect } from 'react';
import CarouselButtons from '../../components/CarouselButtons';
import { projectPageData } from '../../assets/projectPageData'; // Importing dynamic project data

const CompanyJourney = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    // Convert projectPageData into an array of journey slides
    const journeyData = Object.keys(projectPageData).map((key) => {
        const project = projectPageData[key];
        return {
            year: project.RERA, // Use RERA as a placeholder for year
            title: project.heading,
            description: `Located in ${project.location}. ${project.status} project.`,
            image: project.bg,
        };
    });

    const goToNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % journeyData.length);
    };

    const goToPreviousSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + journeyData.length) % journeyData.length);
    };

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className="relative text-white overflow-hidden lg:pt-24 shadow-b-xl">
            {/* Background Image */}
            <img 
                src={journeyData[currentSlide].image} 
                alt={journeyData[currentSlide].title} 
                className="w-full h-[400px] md:h-[600px] lg:h-[800px] object-cover transition-all duration-500 ease-in-out" 
            />

            {/* Content Section */}
            {/* <div className="absolute inset-0 p-4 md:p-8 z-20 justify-center itemns-center text-center bg-gradient-to-r from-onyx via-velvet to-onyx rounded-l-xl shadow-lg max-w-[100%] lg:max-w-[40%]"> */}
            <div className="absolute inset-0 p-4 md:p-8 flex flex-col justify-center items-center z-20 text-center bg-black bg-opacity-60 rounded-l-xl shadow-lg max-w-[100%] lg:max-w-[100%]">
                <div>
                    <p className="text-sm md:text-lg lg:text-xl font-semibold text-champagne">About Us</p>
                    <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold text-silk">Our Journey</h1>
                </div>
                <div>
                    <h3 className="text-lg md:text-2xl lg:text-3xl mt-1 md:mt-2 text-silk">{journeyData[currentSlide].title}</h3>
                    <p className="mt-2 md:mt-4 text-xs md:text-sm lg:text-base text-pearl">{journeyData[currentSlide].description}</p>
                </div>
            </div>

            {/* Dots and Carousel Controls */}
            <div className="absolute bottom-2 left-0 right-0 p-2 md:p-4 flex flex-col sm:flex-row items-center justify-center gap-x-8 z-20">
                <div className="relative flex items-center mx-2 w-full sm:w-auto">
                    <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 h-0.5 bg-white w-full sm:w-[600px]"></div>
                    <div className="flex w-full sm:w-[600px] justify-between">
                        {journeyData.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => goToSlide(index)}
                                className={`relative z-10 transition-all duration-300 transform hover:scale-100 ${index === currentSlide ? 'w-4 h-4 scale-110 bg-champagne shadow-sm' : 'w-3 h-3 bg-white mt-1 hover:bg-gray-200'}`}
                                style={{ borderRadius: '50%' }}
                            ></button>
                        ))}
                    </div>
                </div>
                <CarouselButtons goToPreviousSlide={goToPreviousSlide} goToNextSlide={goToNextSlide}/>
            </div>
        </div>
    );
};

export default CompanyJourney;
