import React from 'react';
import { FaLightbulb } from 'react-icons/fa'; // Import appropriate icons
import visionImage from '../../assets/eecd/vision_image.jpg'; // Import your image

const Vision = () => {
    return (
        <div className="flex flex-col lg:flex-row items-center pb-12 px-4 sm:px-8 lg:px-36 bg-marble">
            {/* Image Section */}
            <div className="lg:w-1/2 pt-12">
                <img
                    src={visionImage} // Replace with your image path
                    alt="Vision"
                    className="w-full h-auto rounded-lg shadow-lg"
                />
            </div>
            {/* Text Section */}
            <div className="lg:w-1/2 my-8 lg:mb-0 lg:pl-12">
                {/* <div className="flex items-left mb-4">
                    <FaLightbulb className="text-champagne text-2xl lg:text-4xl mr-2" />
                    <h2 className="text-xl lg:text-3xl sm:text-4xl font-bold text-onyx">
                        What is our vision?
                    </h2>
                </div> */}
                <p className="text-center text-lg text-gray-700 mb-6">
                    <h2 className="text-xl lg:text-3xl sm:text-4xl font-bold text-onyx mb-4"> What is our vision?</h2> Our vision is to empower the middle class with the dream of homeownership. We recognize that one of the biggest challenges home buyers face is the prolonged wait to move into their new homes. Our commitment is to deliver homes with unmatched speed, exceptional build quality, and at prices that are truly affordable.
                </p>
                <a href='/aboutus' className="bg-onyx text-marble px-6 py-3 rounded-xl font-semibold border-2 border-onyx hover:bg-silk hover:text-onyx transition duration-300">
                    Learn More
                </a>
            </div>
        </div>
    );
};

export default Vision;
