import React from 'react';
import Slider from 'react-slick';

const officerCategories = [
    "IAS Officers",
    "IPS Officers",
    "Class-1 Central/State Officers",
    "Indian Overseas Bank Officers",
    "CCL",
    "CMPDI",
    "Jindal",
    "NTPC",
    "Indian Army",
    "Other Banks"
];

const OurPartners = React.forwardRef((props, ref) => {
    const settings = {
        dots: false, // Remove dots by setting this to false
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <section id="amenities" ref={ref} className="py-8 lg:py-16 bg-pearl text-onyx px-4 lg:px-32">
            <h3 className="text-xl lg:text-3xl font-bold mb-6 text-left">Our Partners</h3>

            {/* Carousel for small screens */}
            <div className="block lg:hidden">
                <Slider {...settings}>
                    {officerCategories.map((category, index) => (
                        <div key={index} className="flex justify-center items-center mb-4">
                            <div className="bg-marble rounded-lg shadow-lg px-6 py-4 w-full text-center">
                                <h3 className="text-lg font-semibold text-champagne">{category}</h3>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Grid layout for larger screens */}
            <div className="hidden lg:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
                {officerCategories.map((category, index) => (
                    <div key={index} className="bg-marble rounded-lg shadow-md flex justify-center items-center p-4 transition-transform duration-300 hover:scale-105">
                        <h3 className="text-lg font-semibold text-champagne">{category}</h3>
                    </div>
                ))}
            </div>
        </section>
    );
});

export default OurPartners;
