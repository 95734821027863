import bg from './pictures/1.jpg';
import { faShieldAlt, faCamera, faWifi, faTree, faBuilding, faGamepad, faSwimmer, faDumbbell, faPrayingHands, faUtensils, faStore, faBank, faCubes, faWater } from '@fortawesome/free-solid-svg-icons';
import twobhk from '../../assets/DiamondCityRanchi/floorplans/2bhk.jpeg';
import threebhk from '../../assets/DiamondCityRanchi/floorplans/3bhk_1290.jpeg';
import threebhk2 from '../../assets/DiamondCityRanchi/floorplans/3bhk_1640.jpeg';

// Import all images from the 'clients' folder
const imagesList = require.context('./pictures', false, /\.(png|jpe?g|svg)$/);
const images = imagesList.keys().map((image) => imagesList(image));

const diamondcityranchi = {
    heading: "Diamond City, Ranchi",
    RERA: "JHRERA/PROJECT/76/2018",  // From the image for Ranchi
    bg: bg, 
    images: images,
    location: "Ranchi, Jharkhand",
    type: "Township",
    status: "Ready to Move",
    amenities: [
      { title: '24 x 7 Security', icon: faShieldAlt },
      { title: 'CCTV Surveillance', icon: faCamera },
      { title: 'Wi-Fi Campus', icon: faWifi },
      { title: 'Garden', icon: faTree },
      { title: 'Club House', icon: faBuilding },
      { title: 'Indoor Games', icon: faGamepad },
      { title: 'Swimming Pool', icon: faSwimmer },
      { title: 'Gym / Health Club', icon: faDumbbell },
      { title: 'Yoga / Meditation Center', icon: faPrayingHands },
      { title: 'Kids Play Zone', icon: faUtensils },
      { title: 'Food Court', icon: faStore },
      { title: 'Shopping Complex', icon: faStore },
      { title: 'ATM / Bank', icon: faBank },
      { title: 'Temple', icon: faPrayingHands },
      { title: 'Lift', icon: faCubes },
      { title: 'Well-designed drainage system', icon: faWater }
    ],
    configuration: "2 & 3 BHK Luxurious Flats",
    possession: "Handover by 2026",
    unitSize: "Ranging from 1014 sq.ft. to 1640 sq.ft.",
    bhkDetails: [
      {
        bhk: "2 BHK",
        size: "1014 - 1056 sq.ft",
        price: "₹ 45 Lakhs* Onwards",
        image: [twobhk]
      },
      {
        bhk: "3 BHK",
        size: "1242 - 1640 sq.ft",
        price: "₹ 75 Lakhs* Onwards",
        image: [threebhk, threebhk2]
      }
    ],
    highlight: {
      heading: "Diamond City, Ranchi- Luxury at astonishing prices",
      content: ""
    },
    locationSection: {
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.0169749448564!2d85.40785617631073!3d23.459852178869735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e52f3764d719%3A0x10fa6c15082895e1!2sDiamond%20City%20New!5e0!3m2!1sen!2sin!4v1725696045865!5m2!1sen!2sin",
      reasons: [
          [
              { text: "Diamond City, Ranchi is close to several ", isBold: false },
              { text: "schools & colleges", isBold: true },
              { text: " such as ", isBold: false },
              { text: "Birla Institute of Technology", isBold: true },
              { text: " (2 km), ", isBold: false },
              { text: "Vikas Vidyalaya", isBold: true },
              { text: " (2.5 km), ", isBold: false },
              { text: "Kerali Public School", isBold: true },
              { text: " (1 km), ", isBold: false },
              { text: "Kolkata Public School", isBold: true },
              { text: " (3.5 km), ", isBold: false },
              { text: "Mount Carmel School", isBold: true },
              { text: " (5.5 km), ", isBold: false },
              { text: "DAV Public School", isBold: true },
              { text: " (3.5 km), ", isBold: false },
              { text: "RTC High School", isBold: true },
              { text: " (3.5 km), and ", isBold: false },
              { text: "Sai Nath University", isBold: true },
              { text: " (3 km).", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "corporates", isBold: true },
              { text: " include the ", isBold: false },
              { text: "Proposed IT Park", isBold: true },
              { text: " (500 meters), ", isBold: false },
              { text: "Silk Park", isBold: true },
              { text: " (1 km), and ", isBold: false },
              { text: "Textile Park", isBold: true },
              { text: " (1 km).", isBold: false }
          ],
          [
              { text: "Railway stations", isBold: true },
              { text: " close to the area include ", isBold: false },
              { text: "Mesra Railway Station", isBold: true },
              { text: " (3 km), ", isBold: false },
              { text: "Chandway Railway Station", isBold: true },
              { text: " (2.5 km), and ", isBold: false },
              { text: "Ranchi Railway Station", isBold: true },
              { text: " (17 km).", isBold: false }
          ],
          [
              { text: "Hospitals", isBold: true },
              { text: " nearby include ", isBold: false },
              { text: "Medanta Hospital", isBold: true },
              { text: " (2.5 km), ", isBold: false },
              { text: "HCG Abdur Razzaque Ansari Cancer Institute", isBold: true },
              { text: " (2.5 km), and ", isBold: false },
              { text: "Curesta Global ACMS Hospital", isBold: true },
              { text: " (2.5 km).", isBold: false }
          ],
          [
              { text: "The ", isBold: false },
              { text: "NH-33 Highway", isBold: true },
              { text: " connecting ", isBold: false },
              { text: "Ranchi", isBold: true },
              { text: " to ", isBold: false },
              { text: "Patna", isBold: true },
              { text: " is just ", isBold: false },
              { text: "2.5 km", isBold: true },
              { text: " away, providing great connectivity.", isBold: false }
          ],
          [
              { text: "Main Chowks", isBold: true },
              { text: " near the area include ", isBold: false },
              { text: "Chandway Chowk", isBold: true },
              { text: " (2.5 km), ", isBold: false },
              { text: "Ormanjhi Chowk", isBold: true },
              { text: " (5 km), and ", isBold: false },
              { text: "Vikas Ring Road Chowk", isBold: true },
              { text: " (3 km).", isBold: false }
          ],
          [
              { text: "The nearest ", isBold: false },
              { text: "airport", isBold: true },
              { text: " is ", isBold: false },
              { text: "Bhagwan Birsa Munda Airport", isBold: true },
              { text: " (23 km), making it convenient for frequent flyers.", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "hotels", isBold: true },
              { text: " include ", isBold: false },
              { text: "Madhuvan Vihar", isBold: true },
              { text: " (3.5 km) and ", isBold: false },
              { text: "Mehta Hotel", isBold: true },
              { text: " (1.5 km).", isBold: false }
          ],
          [
              { text: "For shopping, you can visit ", isBold: false },
              { text: "Reliance Trends", isBold: true },
              { text: " (2.5 km) and ", isBold: false },
              { text: "Smart Bazar", isBold: true },
              { text: " (3 km).", isBold: false }
          ],
          [
              { text: "The closest ", isBold: false },
              { text: "stadiums", isBold: true },
              { text: " are ", isBold: false },
              { text: "Football & Hockey Stadium, Morabadi", isBold: true },
              { text: " (12 km), ", isBold: false },
              { text: "Mega Sports Complex, Khelgaon", isBold: true },
              { text: " (12 km), and ", isBold: false },
              { text: "JSCA International Stadium Complex", isBold: true },
              { text: " (25 km).", isBold: false }
          ],
          [
              { text: "Police stations", isBold: true },
              { text: " nearby include ", isBold: false },
              { text: "BIT Mesra OP", isBold: true },
              { text: " (5 km) and ", isBold: false },
              { text: "Pithoria Thana", isBold: true },
              { text: " (12 km).", isBold: false }
          ],
          [
              { text: "Upcoming infrastructure", isBold: true },
              { text: " includes the development of an ", isBold: false },
              { text: "Outer Ring Road", isBold: true },
              { text: " and the ", isBold: false },
              { text: "ISBT", isBold: true },
              { text: ".", isBold: false }
          ],
          [
              { text: "For nature lovers, the ", isBold: false },
              { text: "Birsa Munda Jaivik Udyan", isBold: true },
              { text: " (6 km) is nearby for a refreshing visit.", isBold: false }
          ]
      ]
  }
};

export default diamondcityranchi;
