import React from 'react';
import CompanyProfile from '../ScreenComponents/AboutPage/ComapnyProfile';
import OurPartners from '../ScreenComponents/AboutPage/OurPartners';
import LeadershipCarousel from '../ScreenComponents/AboutPage/LeadershipCarousel';
import OverviewSection from '../ScreenComponents/AboutPage/OverviewSection';
import StatsAndImage from '../ScreenComponents/AboutPage/StatsAndImage';
import FloatingContactButton from '../components/FloatingContactButton';
import ResidentialProjects from '../ScreenComponents/HomePage/Projects';
import DreamSpaceSection from '../components/DreamSpaceSection';
import {Route, Routes, useLocation} from 'react-router-dom';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

const AboutUs = () => {
    const location = useLocation();

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'About Us', {
            page_path: location.pathname,
        });
    }, [location]);

    return (
        <div className="relative bg-pearl max-w-screen w-full overflow-x-hidden">
            <CompanyProfile/>
            <OverviewSection/>
            <OurPartners/>
            <StatsAndImage/>
            <LeadershipCarousel/>
            <ResidentialProjects className="mt-12 lg:my-12"/>
            <DreamSpaceSection/>
            <FloatingContactButton className="lg:block hidden" />
        </div>
    );
};

export default AboutUs;
