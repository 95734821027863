import React from 'react';
import FAQSection from './FAQSection';
import { useState } from 'react';
import KnowMore from './KnowMore';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

const DreamSpaceSection = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false); // Add state for Contact Us modal

    const openContactModal = () => {

        logEvent(analytics, "Dream Space", {});

        setIsContactModalOpen(true);
    };

    const closeContactModal = () => {
        setIsContactModalOpen(false);
    };

    return (
        <div className="">
            {/* Section 1: Query Section */}
            <section className="flex flex-col justify-center py-16 bg-onyx text-left lg:px-0 ">
                <h2 className="text-2xl md:text-4xl font-bold text-pearl mb-4 px-4 md:px-32">Looking for dream spaces, not sure where to start?</h2>
                <p className="text-lg text-gray-600 mb-6 px-4 md:px-32">Leave us a query and our representative will get back to you.</p>
                <button onClick={openContactModal} className="text-pearl border-2 border-marble px-4 py-3 rounded-xl font-semibold hover:bg-marble hover:text-onyx transition-colors w-48 item-right mx-4 md:mx-32">
                    Get in touch
                </button>
            </section>

            {/* Section 2: Property Info */}
            <section className="py-16 text-left lg:text-left px-4 md:px-32">
                <h3 className="text-2xl md:text-3xl font-bold text-onyx mb-4">EECD– Home at a location you want</h3>
                <p className="text-lg text-gray-700 mb-6">
                    If you are looking to buy a home in a township for your family, EECD is offering just that for you, a mark the epitome of luxury independent living.
                    Diamond City brings to you the upscale comfort and luxury that you could only imagine earlier. Amongst the most resplendent areas within the township, there’s one community of villas that stands out proudly, 'Diamond City'.
                </p>
                <p className="text-sm text-gray-600 mt-6">*Terms & Conditions <a href="#" className="text-blue-600">See Details</a></p>
                <p className="text-sm text-gray-600">#Only for available inventory</p>

                {/* Disclaimer Section */}
                <div className="mt-8 text-sm text-gray-600">
                    <h4 className="font-semibold">Disclaimer</h4>
                    <p className="mt-2">
                        Furniture, fixtures, fittings, equipment, accessories, etc. shown in the images are not included in the sale of the property.
                    </p>
                    <p className="mt-2">
                        **EMI calculator only indicative and the viewer must consult their lender/banker.
                    </p>
                </div>
            </section>
            <FAQSection/>
            <KnowMore isOpen={isContactModalOpen} closeModal={closeContactModal} />
        </div>
    );
};

export default DreamSpaceSection;
