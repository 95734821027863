import React from 'react';
import SiteVisitForm from '../components/SiteVisitForm';
import DreamSpaceSection from '../components/DreamSpaceSection';
import {Route, Routes, useLocation} from 'react-router-dom';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

const ContactUs = () => {

    const location = useLocation();

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Contact Us', {
            page_path: location.pathname,
        });
    }, [location]);

    return (
        <div className="bg-pearl min-h-screen">
            {/* Main Contact Section */}
            <div className="flex flex-col lg:flex-row justify-between items-start px-8 lg:px-16 lg:mt-16 lg:mx-44 py-12">
                {/* Contact Information Section */}
                <div className="lg:w-1/2 text-left space-y-6">
                    <h2 className="text-3xl lg:text-4xl font-bold text-onyx mb-4">Contact Us</h2>
                    <p className="text-lg lg:text-xl text-gray-700">
                        Buying a property is a significant life decision, and we are happy to be with you every step of the way.
                        Whether it is choosing the right location or understanding loan requirements, we will address all your queries.
                    </p>
                    
                    <div className="mt-6">
                        <p className="text-lg lg:text-xl font-semibold text-onyx">For Enquiry</p>
                        <a href="mailto:easternestate@agmail.com" className="text-lg text-blue-500 underline block mb-4">easternestate@agmail.com</a>

                        <p className="text-lg lg:text-xl font-semibold text-onyx">Call</p>
                        <a href="tel:+919939366036" className="text-lg text-blue-500 underline block">+91 99393 66036</a>
                    </div>
                </div>

                {/* Query Form Section */}
                <div className="lg:w-1/2 mt-12 lg:mt-0 lg:pl-16">
                    <SiteVisitForm heading={"Help us solve your queries"} />
                </div>
            </div>

            {/* Optional Other Sections */}
            <div className="mt-16">
                <DreamSpaceSection />
            </div>
        </div>
    );
};

export default ContactUs;
