import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';

const LocationSection = React.forwardRef(({ props }, ref) => {
    return (
        <div id='location' ref={ref} className="flex flex-col items-start py-8 bg-pearl mt-8">
            {/* Map Section */}
            <div className="w-full flex-grow h-[250px] lg:h-[520px] mb-8 lg:mb-0">
                <iframe
                    src={props.mapSrc}
                    width="100%"
                    height="100%"
                    className="rounded-lg min-h-full"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>

            {/* Text Section */}
            <div className="w-full flex-grow">
                <h2 className="text-lg lg:text-xl font-bold text-champagne pt-4">Location</h2>
                <h3 className="text-2xl lg:text-4xl font-bold mt-2 mb-6 text-onyx">Perfectly Positioned</h3>
                <ul className="space-y-4">
                    {props.reasons.map((reason, index) => (
                        <li key={index} className="flex items-start">
                            <span className="text-champagne pt-1 pr-2"><FaMapMarkerAlt className='text-champagne text-xl'/></span>
                            <p className="text-gray-700 text-lg lg:text-xl">
                                {reason.map((part, i) =>
                                    part.isBold ? <strong key={i}>{part.text}</strong> : part.text
                                )}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
});

export default LocationSection;
