import bg from './pictures/img-04.jpg';
import { faShieldAlt, faCamera, faWifi, faTree, faBuilding, faGamepad, faSwimmer, faDumbbell, faPrayingHands, faUtensils, faStore, faBank, faCubes, faWater } from '@fortawesome/free-solid-svg-icons';
import simplexEco from './floorplans/simplex_eco.jpg';
import simplexGold from './floorplans/simplex_gold.jpg';
import duplex from './floorplans/duplex.jpg';

// Import all images from the 'clients' folder
const imagesList = require.context('./pictures', false, /\.(png|jpe?g|svg)$/);
const images = imagesList.keys().map((image) => imagesList(image));

const technocitypatna = {
    heading: "Techno City, Patna",
    RERA: "BRERAP00080-2/647/R-589/2019",  // From the image for Techno City
    bg: bg,
    images: images,
    location: "Patna, Bihar",
    type: "Township",
    status: "Under Construction",
    amenities: [
      { title: '24 x 7 Security', icon: faShieldAlt },
      { title: 'Club House', icon: faBuilding },
      { title: 'Wi-Fi Campus', icon: faWifi },
      { title: 'CCTV Surveillance', icon: faCamera },
      { title: 'Kids Play Zone', icon: faUtensils },
      { title: 'Gym / Health Club', icon: faDumbbell },
    ],
    configuration: "1, 2 & 3 BHK Villas",
    possession: "-",
    unitSize: "Ranging from 435 sq.ft. to 1370 sq.ft.",
    bhkDetails: [
      {
        bhk: "1 BHK (SIMPLEX ECONOMY)",
        size: "435 sq.ft",
        price: "₹ 22 Lakhs Onwards",
        image: [simplexEco]
      },
      {
        bhk: "2 BHK (SIMPLEX GOLD)",
        size: "720 sq.ft",
        price: "₹ 35 Lakhs Onwards",
        image: [simplexGold]
      },
      {
        bhk: "3 BHK (DUPLEX GOLD)",
        size: "1370 sq.ft",
        price: "₹ 55 Lakhs Onwards",
        image: [duplex]
      }
    ],
    highlight: {
      heading: "Techno City, Patna- Make our house your home",
      content: ""
    },
    locationSection: {
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.5087300545656!2d85.12151407636266!3d25.62123597744037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x140d7e7ca99b99e9%3A0x8b8a2ffe59857d0e!2sEastern%20Estate%20Construction%20%26%20Developers%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1725696960482!5m2!1sen!2sin",
      reasons: [
          [
              { text: "Techno City, Patna is close to top ", isBold: false },
              { text: "schools & colleges", isBold: true },
              { text: ", including ", isBold: false },
              { text: "Kendriya School (IIT Campus)", isBold: true },
              { text: " (5 km), ", isBold: false },
              { text: "Kendriya School (Airforce Campus)", isBold: true },
              { text: " (7 km), ", isBold: false },
              { text: "Radiant School, Saguna More", isBold: true },
              { text: " (36 km), ", isBold: false },
              { text: "IIT Bihta", isBold: true },
              { text: " (3 km), and ", isBold: false },
              { text: "NSIT Bihta", isBold: true },
              { text: " (3 km), ensuring ample educational opportunities.", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "corporates", isBold: true },
              { text: " include ", isBold: false },
              { text: "Hero Cycle Factory", isBold: true },
              { text: " (8 km), providing a strong industrial presence.", isBold: false }
          ],
          [
              { text: "For medical needs, the area is close to major ", isBold: false },
              { text: "hospitals", isBold: true },
              { text: ", including ", isBold: false },
              { text: "ESIC Hospital, Bihta", isBold: true },
              { text: " (6 km), ", isBold: false },
              { text: "NSMCH, Bihta", isBold: true },
              { text: " (8 km), and ", isBold: false },
              { text: "AIIMS, Patna", isBold: true },
              { text: " (30 km).", isBold: false }
          ],
          [
              { text: "The location offers easy access to major ", isBold: false },
              { text: "highways", isBold: true },
              { text: ", such as ", isBold: false },
              { text: "Buxar-Patna NH-30", isBold: true },
              { text: " (7 km), ", isBold: false },
              { text: "Bihta-Aurangabad NH-139", isBold: true },
              { text: " (4 km), and ", isBold: false },
              { text: "Sasaram-Patna NH", isBold: true },
              { text: " (0 km), ensuring excellent road connectivity.", isBold: false }
          ],
          [
              { text: "Religious and spiritual destinations are close by, such as ", isBold: false },
              { text: "Bakhorapur Kali Mandir", isBold: true },
              { text: " (30 km), ", isBold: false },
              { text: "Patna ISCON Mandir", isBold: true },
              { text: " (42 km), and ", isBold: false },
              { text: "Patna Mahavir Mandir", isBold: true },
              { text: " (45 km).", isBold: false }
          ],
          [
              { text: "Travel convenience is provided by nearby ", isBold: false },
              { text: "railway stations", isBold: true },
              { text: " such as ", isBold: false },
              { text: "Bihta Railway Station", isBold: true },
              { text: " (5 km), ", isBold: false },
              { text: "Danapur Railway Station", isBold: true },
              { text: " (25 km), and ", isBold: false },
              { text: "Ara Railway Station", isBold: true },
              { text: " (30 km).", isBold: false }
          ],
          [
              { text: "Shopping and retail options are available at ", isBold: false },
              { text: "Fashion Factory", isBold: true },
              { text: " (30 km), ", isBold: false },
              { text: "Reliance Trends", isBold: true },
              { text: " (35 km), and ", isBold: false },
              { text: "P & M Mall", isBold: true },
              { text: " (40 km).", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "stadiums", isBold: true },
              { text: " include ", isBold: false },
              { text: "Jagjivan Stadium", isBold: true },
              { text: " (30 km) and ", isBold: false },
              { text: "Urja Stadium (Dhoni)", isBold: true },
              { text: " (38 km).", isBold: false }
          ],
          [
              { text: "For spiritual well-being, you can visit ", isBold: false },
              { text: "Bakhorapur Kali Mandir", isBold: true },
              { text: " (30 km), and ", isBold: false },
              { text: "Patna ISCON Mandir", isBold: true },
              { text: " (42 km).", isBold: false }
          ],
          [
              { text: "The nearest ", isBold: false },
              { text: "police stations", isBold: true },
              { text: " include ", isBold: false },
              { text: "Bihta Police Station", isBold: true },
              { text: " (6 km) and ", isBold: false },
              { text: "Patna Head Quarter", isBold: true },
              { text: " (33 km).", isBold: false }
          ],
          [
              { text: "For airport travel, ", isBold: false },
              { text: "Bihta Airport", isBold: true },
              { text: " (8 km) and ", isBold: false },
              { text: "Patna Airport", isBold: true },
              { text: " (35 km) are within easy reach.", isBold: false }
          ]
      ]
  }
};

export default technocitypatna;
