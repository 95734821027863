import React, { useState, useEffect } from 'react';
import { HiOutlineChatAlt2 } from 'react-icons/hi'; // Icon for the floating button
import SiteVisitForm from './SiteVisitForm';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';

const FloatingContactButton = ({ isPartOfNavbar, className }) => {
    const [isVisible, setIsVisible] = useState(false); // Form visibility state
    const [formHeading, setFormHeading] = useState("Book A Site Visit!"); // Initial form heading

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Floating contact', {});
    }, []);

    const toggleForm = () => {
        setIsVisible(!isVisible); // Toggle form visibility when the button is clicked
    };

    return (
        <div className={isPartOfNavbar ? `${className} flex items-center` : `${className} block`}>
            {isVisible && (
                <div className="fixed bottom-16 right-8 lg:right-16 p-4 rounded-lg z-50">
                    <SiteVisitForm heading={formHeading} />
                </div>
            )}
            <button
                onClick={toggleForm}
                className={`p-4 rounded-full lg:shadow-lg transition duration-300 
                    ${isVisible ? 'bg-marble text-white' : (isPartOfNavbar ? '' : 'bg-onyx text-black lg:text-pearl lg:hover:bg-gray-800')} 
                    ${isPartOfNavbar ? '' : 'fixed bottom-4 lg:bottom-16 right-4 lg:right-16 z-50'}`}
            >
                <HiOutlineChatAlt2 className="text-3xl lg:text-2xl" />
            </button>
        </div>
    );
};

export default FloatingContactButton;
