import React from 'react';
import Slider from 'react-slick';  // Import the slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const specifications = [
    { id: '01', item: 'FOUNDATION', description: 'Pile And Raft Foundation With Reinforced Cement Concrete' },
    { id: '02', item: 'SUPER STRUCTURE', description: 'Reinforced Column And Beam Structure' },
    { id: '03', item: 'WALLS', description: 'Brick Masonary' },
    { id: '04', item: 'ROOMS', description: `Floor: Superior Vitrified Tiles (24" X 24")\nDado: Vitrified Tiles` },
    { id: '05', item: 'KITCHEN', description: `Floor: Antiskid Tiles\nPlatform: Granite\nDado: Glazed Tiles (Two Feet Above Platform)\nSink: Stainless Steel Sink` },
    { id: '06', item: 'TOILET', description: `Floor: Antiskid Tiles\nDado: Glazed Tiles (7 ft. From Floor Level)\nWC: European` },
    { id: '07', item: 'Fixtures', description: 'Branded High-Quality Fixtures' },
    { id: '08', item: 'WASH BASIN', description: 'Ceramic - 1 In Each Bathroom And Dining\nWashbasin in dining area will be provided with 2 ft. height tiles' },
    { id: '09', item: 'MAIN DOORS', description: 'Wood Flush Door With Veneer Including Good Quality Hinges, Lock And Handle' },
    { id: '10', item: 'WINDOWS', description: 'Branded UPVC Windows' },
    { id: '11', item: 'INSIDE FINISHES', description: 'Wood Framed Flush Doors With Veneer' },
    { id: '12', item: 'INTERNAL FINISHES', description: 'Putty, Primer With Oil Based Distemper' },
    { id: '13', item: 'EXTERNAL FINISHES', description: 'External Weather Proof Paint' },
    { id: '14', item: 'ELECTRICAL', description: 'Concealed Wiring And Modular Switches' },
];

const SpecificationsSection = React.forwardRef((props, ref) => {
    // Slider settings for mobile carousel
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,  // Show one item at a time
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <section id="specifications" ref={ref} className="hidden lg:flex flex-col bg-pearl py-8 lg:py-12">
            <h3 className="text-2xl lg:text-4xl font-bold mb-8 text-onyx">Specifications</h3>

            {/* Carousel for smaller screens */}
            <div className="block lg:hidden">
                <Slider {...sliderSettings}>
                    {specifications.map((spec) => (
                        <div key={spec.id} className="bg-marble p-6 rounded-lg shadow-lg">
                            <h4 className="text-lg font-bold text-champagne mb-2">{spec.item}</h4>
                            <p className="text-gray-700 whitespace-pre-line">{spec.description}</p>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* Table format for larger screens */}
            <div className="hidden lg:block overflow-x-auto  rounded-xl">
                <table className="min-w-full bg-white shadow-md">
                    <thead className="bg-champagne text-onyx">
                        <tr>
                            <th className="px-4 py-2 text-left">Items</th>
                            <th className="px-4 py-2 text-left">Description</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {specifications.map((spec) => (
                            <tr key={spec.id} className="border-b">
                                <td className="px-4 py-2 font-semibold">{spec.item}</td>
                                <td className="px-4 py-2 whitespace-pre-line">{spec.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    );
});

export default SpecificationsSection;
