import React, { useState } from 'react';

function FAQSection() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "What are the key features of your townships in Bihar, Jharkhand, and Odisha?",
            answer: "Our townships offer modern amenities, including well-planned residential areas, green spaces, educational institutions, healthcare facilities, and recreational areas, all designed to enhance quality of life."
        },
        {
            question: "How do I purchase a property in your township?",
            answer: "To purchase a property, you can contact our sales team through our website or visit our office. We will guide you through the selection, documentation, and purchase process to ensure a smooth transaction."
        },
        {
            question: "What types of residential units are available?",
            answer: "We offer a range of residential units, including apartments, villas, and plots, to suit different preferences and budgets. Detailed information about each type can be found on our website or by contacting our sales team."
        },
        {
            question: "What are the payment plans for buying a property?",
            answer: "We offer flexible payment plans, including down payments and installment options. Specific plans can be tailored based on the property type and customer requirements. Please contact us for detailed payment plan options."
        },
        {
            question: "Are the properties in your townships approved by local authorities?",
            answer: "Yes, all our properties are fully approved and compliant with local regulations and zoning laws. We ensure that each project meets all legal and regulatory requirements."
        },
        {
            question: "What are the maintenance and management services provided?",
            answer: "Our townships include comprehensive maintenance and management services, such as 24/7 security, landscaping, waste management, and repair services, to ensure a well-maintained and secure environment for residents."
        },
        {
            question: "What are the future development plans for these townships?",
            answer: "We have planned future expansions and developments, including additional amenities, commercial spaces, and infrastructural enhancements, to continuously improve the living experience in our townships."
        },
        {
            question: "How can I visit the township site?",
            answer: "You can schedule a site visit by contacting our sales team. We will arrange a guided tour of the township, where you can explore the amenities and available properties."
        },
        {
            question: "What are the advantages of living in a township compared to individual properties?",
            answer: "Living in a township offers several advantages, including access to a wide range of amenities, a well-planned community environment, enhanced security, and proximity to essential services and facilities."
        },
        {
            question: "Do you offer any special discounts or promotions?",
            answer: "We periodically offer special discounts and promotions. Please contact our sales team or visit our website to stay updated on current offers and promotional events."
        }
    ];

    return (
        <div className="bg-marble py-12 px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl lg:text-4xl font-bold text-center text-onyx mb-10">FREQUENTLY ASKED QUESTIONS</h2>
            <div className="max-w-4xl mx-auto space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="bg-pearl shadow-lg rounded-xl transition duration-300 hover:shadow-xl">
                        <button
                            onClick={() => toggleFAQ(index)}
                            className="flex justify-between items-center w-full text-left px-6 py-4 text-onyx font-semibold focus:outline-none transition-all duration-200"
                        >
                            <span>{faq.question}</span>
                            <span className="text-2xl">{activeIndex === index ? '-' : '+'}</span>
                        </button>
                        {activeIndex === index && (
                            <div className="px-6 pb-4 text-gray-700 transition-opacity duration-300 ease-in-out text-left">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQSection;
