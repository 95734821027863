import React from 'react';

import '../App.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PropertyHeader from '../ScreenComponents/ProjectPage/PropertyHeader';
import FeedbackSection from '../ScreenComponents/HomePage/FeedbackSection';
import BlogSection from '../ScreenComponents/BlogSection';
import Subscribe from '../ScreenComponents/Subscribe';
import PropertyInfoSection from '../ScreenComponents/ProjectPage/PropertyInfoSection';
import FAQSection from '../components/FAQSection';
import FloatingContactButton from '../components/FloatingContactButton';
import { useParams } from 'react-router-dom';
import { projectPageData } from '../assets/projectPageData';
import EMI_Calculator from '../ScreenComponents/ProjectPage/EMI_Calculator';
import DreamSpaceSection from '../components/DreamSpaceSection';
import {Route, Routes, useLocation} from 'react-router-dom';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

function PropertyPage() {
    const { projectId } = useParams();
    const projectData = projectPageData[`${projectId}`];

    const location = useLocation();

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, projectId, {
            page_path: location.pathname,
        });
    }, [location]);

    return (
        <div className="relative bg-pearl max-w-full pt-14 lg:pt-36">
            <PropertyHeader data={projectData}/>
            <PropertyInfoSection data={projectData}/>
            <EMI_Calculator/>
            <FeedbackSection/>
            <DreamSpaceSection/>
            <FloatingContactButton className="lg:block hidden" />
        </div>
    );
}

export default PropertyPage;
