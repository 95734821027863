import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import image from '../../assets/eecd/ad_design.png';
import HighlightSection from './PropertyInfoSectionComponents/HighlightSection';
import AmenitiesSection from './PropertyInfoSectionComponents/AmenitiesSection';
import PriceListSection from './PropertyInfoSectionComponents/PriceListSection';
import FloorPlanSection from './PropertyInfoSectionComponents/FloorPlanSection';
import LocationSection from './PropertyInfoSectionComponents/LocationSection';
import SpecificationsSection from './PropertyInfoSectionComponents/SpecificationsSection';

const tabs = [
    { key: 'highlight', title: 'Highlight' },
    { key: 'amenities', title: 'Amenities' },
    { key: 'pricelist', title: 'Price List' },
    { key: 'floorplan', title: 'Floor Plan' },
    { key: 'specifications', title: 'Specifications' },
    { key: 'location', title: 'Location' },
];

const PropertyInfoSection = ({data}) => {
    const sectionRefs = useRef({});
    const navRef = useRef(null);
    const [activeTab, setActiveTab] = useState('highlight');
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 2;

            // Get the top positions of the highlight and location sections
            const highlightTop = sectionRefs.current['highlight']?.offsetTop || 0;
            const locationBottom = (sectionRefs.current['location']?.offsetTop || 0) + (sectionRefs.current['location']?.offsetHeight || 0);

            // Check if the tabs should be fixed only between highlight and location sections
            if (window.scrollY >= highlightTop && window.scrollY < locationBottom) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }

            // Set active tab based on scroll position
            for (const tab of tabs) {
                const ref = sectionRefs.current[tab.key];
                if (ref) {
                    const { offsetTop, offsetHeight } = ref;
                    if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
                        setActiveTab(tab.key);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="lg:px-48">
            {/* Navigation Tabs */}
            <div
                ref={navRef}
                className={`pt-4 border-b border-gray-300 ${isFixed ? 'fixed top-0 lg:top-24 lg:left-32 w-full bg-white z-10' : ''}`}
            >
                <nav className="flex lg:space-x-4 overflow-x-auto">
                    {tabs.map((tab) => (
                        <Link
                            key={tab.key}
                            to={tab.key}
                            smooth={true}
                            duration={500}
                            offset={-50} // Adjust offset for fixed header height
                            className={`cursor-pointer py-2 px-4 border-b-2 font-medium text-lg ${
                                activeTab === tab.key
                                    ? 'border-onyx text-onyx'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                            {tab.title}
                        </Link>
                    ))}
                </nav>
            </div>

            {/* Sections */}
            <div className='text-left px-4 lg:px-0'>
                <HighlightSection ref={(el) => (sectionRefs.current['highlight'] = el)} title={data.highlight.heading} content={data.highlight.content} />
                <AmenitiesSection ref={(el) => (sectionRefs.current['amenities'] = el)} amenities={data.amenities} />
                <PriceListSection ref={(el) => (sectionRefs.current['pricelist'] = el)} priceList={data.bhkDetails}/>
                <FloorPlanSection ref={(el) => (sectionRefs.current['floorplan'] = el)} floorPlans={data.bhkDetails}/>
                <SpecificationsSection ref={(el) => (sectionRefs.current['specifications'] = el)}/>
                <LocationSection ref={(el) => (sectionRefs.current['location'] = el)} props={data.locationSection}/>
            </div>
        </div>
    );
};

export default PropertyInfoSection;
