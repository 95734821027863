import React from 'react';

import '../App.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import WhyChooseUs from '../ScreenComponents/HomePage/WhyChooseUs';
import Vision from '../ScreenComponents/HomePage/Vision';
import FeedbackSection from '../ScreenComponents/HomePage/FeedbackSection';
import BlogSection from '../ScreenComponents/BlogSection';
import image from '../assets/eecd/flex_10.jpeg';
import DiamondCitySection from '../ScreenComponents/HomePage/DiamondCitySection';
import ResidentialProjects from '../ScreenComponents/HomePage/Projects';
import Subscribe from '../ScreenComponents/Subscribe';
import FloatingContactButton from '../components/FloatingContactButton';
import DreamSpaceSection from '../components/DreamSpaceSection';
import {Route, Routes, useLocation} from 'react-router-dom';
import { analytics } from '../firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';

function HomePage() {

    const location = useLocation();

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Home Page', {
            page_path: location.pathname,
        });
    }, [location]);

    return (
        <div className="bg-pearl max-w-screen w-full overflow-x-hidden">
            <DiamondCitySection image={image}/>
            <ResidentialProjects className="mt-12 lg:-mt-10"/>
            <WhyChooseUs/>
            <Vision/>
            <FeedbackSection/>
            <DreamSpaceSection/>
            {/* <BlogSection/> */}
            {/* <Subscribe/> */}
            <FloatingContactButton className="lg:block hidden" />
        </div>
    );
}

export default HomePage;
