import React from 'react';
import image1 from '../../assets/eecd/flex_3.jpeg'
import image2 from '../../assets/eecd/flex_2.jpeg'

const OverviewSection = () => {
    return (
        <section className="flex flex-col lg:flex-row relative px-4 lg:px-32 bg-pearl text-left py-12">
            <div className="mb-8 lg:mb-0">
                <h2 className="text-champagne font-semibold text-3xl mb-4">EECD</h2>
                <h3 className="text-onyx text-3xl lg:text-4xl font-bold mb-6" style={{ fontFamily: 'Dancing Script, cursive' }}>Life Long Bonding</h3>

                <div className='flex flex-row gap-x-16'>
                    <img
                        src={image2} 
                        alt="Building"
                        className="hidden lg:block w-full lg:w-64 h-auto rounded-lg shadow-md"
                    />
                    <div className="lg:w-2/3 flex flex-col justify-center">
                        <p className="text-left text-gray-700 mb-6">
                            Eastern Estate Constructions and Developers is a renowned name in the real estate industry, known for its commitment to quality, innovation, and customer satisfaction. With a rich legacy of over a decade, the company has successfully completed numerous projects, ranging from expansive residential townships to state-of-the-art commercial complexes. Each project is a testament to their unwavering dedication to excellence, with a focus on creating spaces that combine functionality with aesthetic appeal.
                        </p>
                        <p className="text-left text-gray-700">
                            In addition to their commitment to quality, Eastern Estate Constructions and Developers is also known for its emphasis on sustainability and environmental responsibility. By blending modern architecture with green building practices, they are creating communities that stand the test of time, fostering a sense of belonging and enhancing the overall well-being of their inhabitants.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;
