import React, { useState } from 'react';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaBed, FaCalendarCheck, FaExpandArrowsAlt, FaMapMarkerAlt } from 'react-icons/fa';
import KnowMore from '../../components/KnowMore';

// Set the app element for accessibility
Modal.setAppElement('#root');

const PropertyHeader = ({ data }) => {

    const shareContent = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Diamond City Project',
                text: 'Check out this amazing project!',
                url: window.location.href // Share the current page's URL
            })
            .then(() => console.log('Content shared successfully!'))
            .catch((error) => console.error('Error sharing:', error));
        } else {
            alert('Your browser does not support the Web Share API.');
        }
    };

    // Property details array
    const propertyDetails = [
        {
            icon: <FaBed className='text-champagne'/>,
            title: 'Configuration',
            description: data.configuration,
        },
        {
            icon: <FaCalendarCheck className='text-champagne'/>,
            title: 'Ready to Move In',
            description: data.possession,
        },
        {
            icon: <FaExpandArrowsAlt className='text-champagne'/>,
            title: 'Unit Size (RERA Carpet Area)',
            description: data.unitSize,
        },
        {
            icon: <FaMapMarkerAlt className='text-champagne'/>,
            title: 'Project Area',
            description: 'Available on request',
        },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const openModal = (index) => {
        setSelectedIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isContactModalOpen, setIsContactModalOpen] = useState(false); // Add state for Contact Us modal

    const openContactModal = () => {
        setIsContactModalOpen(true);
    };

    const closeContactModal = () => {
        setIsContactModalOpen(false);
    };


    return (
        <div className="rounded-lg pb-8 px-4 lg:px-48">
            {/* Project Heading */}
            <div className="text-left mb-6">
                <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-2">
                    <h1 className="text-2xl md:text-3xl font-bold text-onyx">{data.heading}</h1>
                    <p className="text-sm md:text-lg text-gray-600 mt-2 md:mt-0">RERA: {data.RERA}</p>
                </div>
                <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                    <button onClick={openContactModal} className="text-sm md:text-lg text-gray-600 underline text-left hover:underline">Book a Visit</button>
                    <p className="text-sm md:text-lg text-gray-600 mt-2 md:mt-0">
                        {/* <a href="/path-to-brochure.pdf" download className="underline">Download Brochure</a> |  */}
                        <button onClick={shareContent} className="underline ml-2 hover:underline">Share</button>
                    </p>
                </div>
            </div>

            {/* Property Images */}
            <div className="flex flex-col lg:flex-row h-auto gap-4">
                <div className="relative w-full lg:w-2/3 h-full cursor-pointer" onClick={() => openModal(0)}>
                    <img src={data.images[0]} alt="Property" className="w-full h-[520px] object-cover rounded-3xl" />
                    <div className="absolute bottom-2 right-2 bg-black text-white text-sm px-2 py-1 rounded hidden lg:block">
                        + {data.images.length} photos
                    </div>
                </div>
                <div className="hidden lg:flex flex-col w-1/3 h-full gap-4">
                    {data.images.slice(1, 3).map((img, index) => (
                        <div key={index} className="relative cursor-pointer" onClick={() => openModal(index + 1)}>
                            <img src={img} alt={`Property ${index + 1}`} className="w-full h-[250px] object-cover rounded-3xl" />
                        </div>
                    ))}
                </div>
            </div>

            {/* Property Details */}
            <div className="flex flex-wrap justify-between items-center py-6 border-t mt-6">
                {propertyDetails.map((detail, index) => (
                    <div key={index} className="flex items-center mb-4 lg:mb-0 w-full lg:w-auto">
                        <div className="text-gray-700 text-2xl mr-4">
                            {detail.icon}
                        </div>
                        <div className="text-left">
                            <h3 className="text-sm text-gray-600">{detail.title}</h3>
                            <p className="font-bold text-onyx">{detail.description}</p>
                        </div>
                        {index < propertyDetails.length - 1 && (
                            <div className="hidden lg:block border-l h-8 mx-4"></div>
                        )}
                    </div>
                ))}
            </div>

            {/* Modal for Carousel */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Property Images"
                className="fixed inset-0 flex items-center justify-center p-4"
                overlayClassName="fixed inset-0 bg-black bg-opacity-75"
            >
                <div className="relative bg-white rounded-lg overflow-hidden w-full max-w-4xl">
                    <button
                        className="absolute z-10 top-8 right-8 text-onyx text-3xl font-bold focus:outline-none"
                        onClick={closeModal}
                    >
                        &times;
                    </button>
                    <Carousel selectedItem={selectedIndex} showThumbs={false} showIndicators={true}>
                        {data.images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Property ${index + 1}`} className="w-full h-[500px] object-cover" />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </Modal>
            <KnowMore isOpen={isContactModalOpen} closeModal={closeContactModal} />
        </div>
    );
};

export default PropertyHeader;
