import bg from './pictures/26.jpg';
import { faShieldAlt, faCamera, faWifi, faTree, faBuilding, faGamepad, faSwimmer, faDumbbell, faPrayingHands, faUtensils, faStore, faBank, faCubes, faWater } from '@fortawesome/free-solid-svg-icons';
import twobhk from './floorplans/C_2bhk.png';
import twobhk2 from './floorplans/C_2bhk_2.png';
import twofivebhk from './floorplans/C_25bhk.png';
import twofivebhk2 from './floorplans/C_25bhk_2.png';
import threebhk from './floorplans/C_3bhk.png';
import threebhk2 from './floorplans/C_3bhk_2.png';

// Import all images from the 'clients' folder
const imagesList = require.context('./pictures', false, /\.(png|jpe?g|svg)$/);
const images = imagesList.keys().map((image) => imagesList(image));

const diamondcitybhubaneswar = {
    heading: "Diamond City, Bhubaneswar",
    RERA: "MP/07/2024/01161", // From the image for Bhubaneswar
    bg: bg, 
    images: images,
    location: "Bhubaneswar, Odisha",
    type: "Township",
    status: "Under Construction",
    amenities: [
      { title: '24 x 7 Security', icon: faShieldAlt },
      { title: 'CCTV Surveillance', icon: faCamera },
      { title: 'Wi-Fi Campus', icon: faWifi },
      { title: 'Garden', icon: faTree },
      { title: 'Club House', icon: faBuilding },
      { title: 'Indoor Games', icon: faGamepad },
      { title: 'Swimming Pool', icon: faSwimmer },
      { title: 'Gym / Health Club', icon: faDumbbell },
      { title: 'Yoga / Meditation Center', icon: faPrayingHands },
      { title: 'Kids Play Zone', icon: faUtensils },
      { title: 'Food Court', icon: faStore },
      { title: 'Shopping Complex', icon: faStore },
      { title: 'ATM / Bank', icon: faBank },
      { title: 'Temple', icon: faPrayingHands },
      { title: 'Lift', icon: faCubes },
      { title: 'Well-designed drainage system', icon: faWater }
    ],
    configuration: "2, 2.5 & 3 BHK Luxurious Flats",
    possession: "Handover by 2026",
    unitSize: "Ranging from 919.7 sq.ft. to 1741 sq.ft.",
    bhkDetails: [
      {
        bhk: "2 BHK",
        size: "919.7 - 1279 sq.ft",
        price: "₹ 45 Lakhs* Onwards",
        image: [twobhk, twobhk2]
      },
      {
        bhk: "2.5 BHK",
        size: "1133 - 1012.5 sq.ft",
        price: "₹ 55 Lakhs* Onwards",
        image: [twofivebhk, twofivebhk2]
      },
      {
        bhk: "3 BHK",
        size: "1569 - 1741 sq.ft",
        price: "₹ 75 Lakhs* Onwards",
        image: [threebhk, threebhk2]
      }
    ],
    highlight: {
      heading: "Diamond City, Bhubaneswar- Luxury at astonishing prices",
      content: ""
    },
    locationSection: {
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3856.069973572579!2d85.88354890990665!3d20.39080321296383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a190d7381997a03%3A0xdf9ffaab3ffb19c5!2sDiamond%20City%20-%20Real-estate%20company%20in%20Cuttack%20%7C%20Flats%20in%20Cuttack!5e0!3m2!1sen!2sin!4v1725176870512!5m2!1sen!2sin",
      reasons: [
          [
              { text: "The residential area is conveniently close to well-known ", isBold: false },
              { text: "schools & colleges", isBold: true },
              { text: " such as ", isBold: false },
              { text: "DPS School", isBold: true },
              { text: " (5 km), ", isBold: false },
              { text: "DAV Public School", isBold: true },
              { text: " (7 km), ", isBold: false },
              { text: "Rama Krishna Murti School", isBold: true },
              { text: " (2 km), ", isBold: false },
              { text: "Cuttack College", isBold: true },
              { text: " (8.1 km), ", isBold: false },
              { text: "Netaji Subash Memorial City College", isBold: true },
              { text: " (7.5 km), ", isBold: false },
              { text: "Sri Jaydev College", isBold: true },
              { text: " (7 km), ", isBold: false },
              { text: "East Engineering College", isBold: true },
              { text: " (6 km), ", isBold: false },
              { text: "Rajdhani Engineering College", isBold: true },
              { text: " (7.2 km), ", isBold: false },
              { text: "Utkal University", isBold: true },
              { text: " (11.4 km), and ", isBold: false },
              { text: "Ravenshaw University", isBold: true },
              { text: " (8.3 km).", isBold: false }
          ],
          [
              { text: "Nearby ", isBold: false },
              { text: "corporates", isBold: true },
              { text: " include ", isBold: false },
              { text: "Konark Office Premises", isBold: true },
              { text: " (1.2 km) and ", isBold: false },
              { text: "Wow! Momo Regional Office", isBold: true },
              { text: " (2.1 km).", isBold: false }
          ],
          [
              { text: "The area is well connected via ", isBold: false },
              { text: "railway stations", isBold: true },
              { text: " like ", isBold: false },
              { text: "Bhubaneswar New Junction", isBold: true },
              { text: " (4.2 km), ", isBold: false },
              { text: "Bhubaneswar Railway Station", isBold: true },
              { text: " (12.5 km), and ", isBold: false },
              { text: "Cuttack Railway Station", isBold: true },
              { text: " (7 km).", isBold: false }
          ],
          [
              { text: "Hospitals", isBold: true },
              { text: " in close proximity include ", isBold: false },
              { text: "Panda Cancer Hospital", isBold: true },
              { text: " (700 meters), ", isBold: false },
              { text: "Max Hospital", isBold: true },
              { text: " (1.5 km), ", isBold: false },
              { text: "SCB Medical College", isBold: true },
              { text: " (7 km), and ", isBold: false },
              { text: "Sum-II Hospital", isBold: true },
              { text: " (2 km).", isBold: false }
          ],
          [
              { text: "The ", isBold: false },
              { text: "NH-16 Highway", isBold: true },
              { text: " connecting ", isBold: false },
              { text: "Kolkata", isBold: true },
              { text: " to ", isBold: false },
              { text: "Vishakhapatnam", isBold: true },
              { text: " is just ", isBold: false },
              { text: "100 meters", isBold: true },
              { text: " away, providing excellent road connectivity.", isBold: false }
          ],
          [
              { text: "Temples", isBold: true },
              { text: " nearby include ", isBold: false },
              { text: "Dhakulei Temple", isBold: true },
              { text: " (1 km), ", isBold: false },
              { text: "Lakheshwar Temple", isBold: true },
              { text: " (3 km), and ", isBold: false },
              { text: "Lingraj Temple", isBold: true },
              { text: " (14 km).", isBold: false }
          ],
          [
              { text: "Shopping destinations", isBold: true },
              { text: " include ", isBold: false },
              { text: "Anama Market", isBold: true },
              { text: " (800 meters), ", isBold: false },
              { text: "SJBL Square Mall", isBold: true },
              { text: " (1.5 km), and ", isBold: false },
              { text: "Symphony Mall", isBold: true },
              { text: " (6 km).", isBold: false }
          ],
          [
              { text: "The area is close to key travel hubs, including the ", isBold: false },
              { text: "Netaji Cuttack Bus Terminal", isBold: true },
              { text: " (6 km) and ", isBold: false },
              { text: "Biju Patnaik International Airport", isBold: true },
              { text: " (18 km), making both public transport and air travel easily accessible.", isBold: false }
          ],
          [
              { text: "Upcoming infrastructure", isBold: true },
              { text: " developments include the ", isBold: false },
              { text: "RI Office", isBold: true },
              { text: " (1 km), a ", isBold: false },
              { text: "Housing Complex", isBold: true },
              { text: " (3 km), and ", isBold: false },
              { text: "Trisulia Metro Station", isBold: true },
              { text: " (5 km).", isBold: false }
          ]
      ]
  }
};

export default diamondcitybhubaneswar;
