import React, { useState } from 'react';
import { projectPageData } from '../../assets/projectPageData';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ResidentialProjects.css'; // Import custom CSS for additional styling

const ResidentialProjects = ({className}) => {
    const [filter, setFilter] = useState('All');

    const projects = Object.values(projectPageData); // Convert projectPageData to an array

    const filteredProjects = filter === 'All' ? projects : projects.filter(project => project.status === filter);

    // Slick slider settings
    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Default number of items to show on larger screens
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px', // No padding around the center item
        dots: false, // Hide dots
        responsive: [
            {
                breakpoint: 1024, // Tablet screens
                settings: {
                    slidesToShow: 2, // 2 items for tablet
                    centerPadding: '0px',
                }
            },
            {
                breakpoint: 640, // Mobile screens
                settings: {
                    slidesToShow: 1, // 1 item for mobile (show one full item)
                    centerPadding: '0px', // Remove padding to avoid showing half items
                }
            }
        ]
    };    

    return (
        <section className={`container mx-auto pb-8 h-[480px] lg:h-[510px] ${className}`}>
            <h2 className="text-xl lg:text-3xl font-bold mb-6 text-left text-onyx px-4">Residential Projects</h2>

            {/* Filter Tabs */}
            <div className="hidden lg:flex space-x-4 sm:space-x-8 mb-6">
                {['All', 'Ready to Move', 'Completed', 'Under Construction'].map(category => (
                    <button
                        key={category}
                        className={`text-lg ${filter === category ? 'border-b-2 border-onyx' : ''}`}
                        onClick={() => setFilter(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            {/* Projects Display */}
            {filteredProjects.length > 3 ? (
                <Slider {...sliderSettings}>
                    {filteredProjects.map((project, index) => (
                        <a href={`/${project.heading.toLowerCase().replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, '')}`} key={index} className="bg-marble rounded-3xl overflow-hidden shadow-md mx-2"> {/* Margin for spacing */}
                            <div className="relative">
                                <img src={project.images[0]} alt={project.heading} className="w-full h-64 object-cover"/>
                                <span className="absolute bottom-2 right-2 bg-white text-gray-700 text-sm px-2 py-1 rounded">{project.tag}</span>
                            </div>
                            <div className="p-4">
                                <h3 className="text-xl font-bold">{project.heading}</h3>
                                <p className="text-gray-600">{project.location}</p>
                                <p className="text-gray-600">{project.type}</p>
                            </div>
                        </a>
                    ))}
                </Slider>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredProjects.map((project, index) => (
                        <div key={index} className="bg-gray-100 rounded-lg overflow-hidden shadow-md">
                            <div className="relative">
                                <img src={project.images[0]} alt={project.heading} className="w-full h-64 object-cover"/>
                                <span className="absolute bottom-2 right-2 bg-white text-gray-700 text-sm px-2 py-1 rounded">{project.tag}</span>
                            </div>
                            <div className="p-4">
                                <h3 className="text-xl font-bold">{project.heading}</h3>
                                <p className="text-gray-600">{project.location}</p>
                                <p className="text-gray-600">{project.type}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
};

export default ResidentialProjects;
